<template>
  <div style="padding:50px;">
    <createForm v-if="type" :operations="formData.operations" :list="formData.fields"
      :inline="formData.inline" :labelWidth="formData.labelWidth"
      :labelPosition="formData.labelPosition" :size="formData.size" :formWidth="formData.formWidth">
    </createForm>

    <tableView v-if="!type" :tableParams="tableData"></tableView>

  </div>
</template>

<script>
import tableView from "@/components/tableView";
import createForm from "@/components/dynamicForm/createForm";

export default {
  data() {
    return {
      type: null,
      formData: {},
      tableData: {},
    };
  },
  async created() {
    this.getDate();
  },
  methods: {
    getDate() {
      const { date, type } = this.$route.params;
      if (date) {
        this.type = type;
        if (type) {
          this.formData = date;
        } else {
          this.tableData = date;
        }
      } else {
        this.$message({
          message: "请重新选择表单和列表",
          type: "warning",
        });
        setTimeout(() => {
          this.$router.replace("/");
        }, 800);
      }
    },
  },
  components: {
    tableView,
    createForm,
  },
};
</script>

<style scoped>
</style>